@import '../../../../scss/theme-bootstrap';

.offers-banner-formatter {
  background-color: $color-belgian-linen;
  margin-bottom: -1px;
  display: flex;
  transition: background-color 500ms ease 0s;
  &:empty {
    display: none;
  }
  &:not(.slick-initialized) {
    .offers-banner-formatter__item {
      display: none;
      width: 100%;
      &:first-of-type {
        display: block;
      }
    }
  }
  .slick-list {
    flex: 0 0 100%;
  }
  .carousel-controls {
    top: 24%;
    @include breakpoint($medium-up) {
      top: 15%;
    }
    .slick-arrow {
      height: 16px;
      min-width: auto;
      top: 0;
      width: 20px;
      &:before {
        width: 15px;
        height: 15px;
        line-height: 1;
        background-color: var(--offer-banner-arrow-color, $color-off-black);
      }
      @include breakpoint($medium-up) {
        height: 20px;
        top: -1px;
        &::before {
          height: 20px;
          width: 20px;
        }
      }
    }
    .slick-prev {
      #{$ldirection}: 8px;
    }
    .slick-next {
      #{$rdirection}: 8px;
      z-index: 0;
      @include breakpoint($medium-up) {
        z-index: 2;
      }
    }
  }
  .section-avhair-quiz & {
    display: none;
    @include breakpoint($portrait-up) {
      display: flex;
    }
  }
}
